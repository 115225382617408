.btn-outline {
    display: block;
    width: 13.75rem;
    border: 1px solid #17da93;
    color: #17da93;
    text-align: center;
    padding: 1.2rem .5rem;
    font-weight: 500;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: $font-sans;
    background: transparent;
    &:hover {
        color: #fff;
        text-decoration: none;
        background: #17da93;
    }
}

.btn-custom {
    display: block;
    width: 13.75rem;
    border: 1px solid #17da93;
    color: #fff;
    background: #17da93;
    text-align: center;
    padding: 1.2rem .5rem;
    font-weight: 500;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: $font-sans;
    &:hover {
        color: #17da93;
        text-decoration: none;
        background: transparent;
    }
}

.btn-link {
    font-weight: 500;
    font-size: 1.4rem;
    color: #17DA93;
    font-family: $font-sans;
    &:hover {
        color: #17DA93;
    }
}

@media screen and (max-width: 768px) {
    .btn-link {
        font-size: 1.1rem;
    }
    .btn-outline {
        font-size: 1.1rem;
    }
    .btn-custom {
        font-size: 1.1rem;
    }
}