footer {
    .contact-row {
        
        .contact-col {
            min-height: 590px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #262C32;
            .contact {
                color: #fff;
                h4 {
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 3rem;
                    margin-bottom: 2rem;                    
                    text-transform: uppercase;
                }
                p {
                    font-weight: 500;
                    font-size: 1.5rem;
                    line-height: 1.6rem;
                }
            }
        }
        .map-col {
            min-height: 590px;
            padding: 0;
            #map {
                height: 100%;
            }
        }
    }
    .copyright-row {
        justify-content: center;
        .copyright-col {
            text-align: center;
            font-size: .9rem;
            color: #4F4F4F;
        }
    }
}

@media screen and (max-width: 768px) {
    footer {
        .contact-row {
            .contact-col {
                min-height: 350px;
                justify-content: flex-start;
                .contact {
                    h4 {
                        font-size: 1.1rem;
                        line-height: 2rem;
                    }
                    p {
                        font-size: 1rem;
                        line-height: 1rem;
                    }
                }                
            }
        }        
        .contact-row {
            .contact-col {
                min-height: 330px;
            }
        }
        
    }
}