.contact-form {
    .contact-form-row {
        align-items: center;
        & > div {
            flex: 1;
        }
        img {
            width: 100%;
        }
    }
    .contact-row {
        margin-top: -80px;
        .contact-item {
            display: inline-block;
        }
        .contact-col {
            padding: 3rem 1rem 3rem 12%;
            background: linear-gradient(to right, #17DA93 0%,#17DA93 10%,#20252A 10%,#20252A 10%,#20252A 100%);
            color: #fff;
            .contact-item {
                margin-right: 2rem;
                h4 {
                    margin-bottom: 1.5rem;
                }
                p {
                    margin-bottom: 0.6rem;
                    opacity: 0.9;
                }
            }
        }
    }
    .title {
        font-size: 2.25rem;
        width: 90%;
    }
    .subtitle {
        font-size: 1.5rem;
        color: #333333;
    }
    .form-wrapper {
        font-family: IBM Plex Sans;
        font-size: 1.14rem;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.25rem;
        & > div {
            flex: 1;
            margin-right: 1.25rem;
        }
        input {
            width: 100%;
            margin-right: 1.25rem;
            padding: 1.25rem 0.6rem 1.25rem 0.6rem;
            border: none;
        }
        input#name {
            margin-bottom: 1.25rem;
            background: url('../img/user.svg') no-repeat 12px 12px;
            background-position: 0.6rem center;
            padding-left: 2rem;
            background-color: #F4F4F4;
        }
        input#email {
            margin-bottom: 1.25rem;
            background: url('../img/email.svg') no-repeat 12px 12px;
            background-position: 0.6rem center;
            padding-left: 2rem;
            background-color: #F4F4F4;
        }
        input#tel {
            background: url('../img/telephone.svg') no-repeat 12px 12px;
            background-position: 0.6rem center;
            padding-left: 2rem;
            background-color: #F4F4F4;
        }
        textarea {
            padding: 1.25rem 0.6rem 1.25rem 0.6rem;
            width: 100%;
            height: 100%;
            border: none;
            background: #F4F4F4;
            resize: none;
        }
    }
    .btn-outline {
        margin: auto;
    }
}

@media screen and (max-width: 1600px) {
    .contact-form {
        .contact-form-row {
            align-items: flex-start;
        }
    }
}

@media screen and (max-width: 1400px) {
    .contact-form {
        .contact-row {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 1199px) {
    .contact-form {
        .contact-form-row {
            & > div {
                flex: unset;
            }
        }
        .contact-row {
            margin-top: -80px;
        }
    }
}

@media screen and (max-width: 768px) {
    .contact-form {
        .form-wrapper {
            & > div {
                flex: unset;
                margin-right: 0;
                width: 100%;
            }
            input#tel {
                margin-bottom: 1.25rem;
            }
        }
        .contact-row {
            .contact-col {
                .contact-item {
                    &:first-of-type {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
        .title {
            line-height: 3rem;
            width: 100%;
        }
    }
}