$font-serif: "IBM Plex Serif", serif;
$font-sans: "IBM Plex Sans", sans-serif;

@import "~bootstrap/scss/bootstrap";
$slick-font-path: "/node_modules/slick-carousel/slick/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/node_modules/slick-carousel/slick-carousel/slick/" !default;
$slick-arrow-color: black !default;
@import "../utils/slick/slick.scss"; 
@import "../utils/slick/slick-theme.scss";

@import "./includes/slider";
@import "./includes/header";
@import "./includes/footer";
@import "./includes/threeColumns";
@import "./includes/buttons";
@import "./includes/leftImage";
@import "./includes/fiveIcons";
@import "./includes/carousel";
@import "./includes/teamList";
@import "./includes/rightImage";
@import "./includes/hero";
@import "./includes/heroShifted";
@import "./includes/contactForm";
@import "./includes/multiForm";
@import "./includes/thankYou";
@import "./includes/notFound";

@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/IBMPlexSerif-Regular.ttf);
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/IBMPlexSans-Regular.ttf);
}

body {
  font-family: $font-serif;
  p, .subtitle {
    font-family: $font-sans;
  }
  .title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 4rem;
    text-transform: uppercase;
    color: #333333;
  }
  .subtitle {
    font-weight: 500;
    font-size: 1.75rem;
    color: #007DEF;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  body {
    .title {
      font-size: 1.1rem;
      line-height: 2rem;
    }
    .subtitle {
      font-size: 1.1rem;
    }
  }
}