.three-columns {
    .row {
        justify-content: space-between;
        .col {
            .img-wrapper {
                width: 10.7rem;
                height: 10.7rem;
                background: #F3F5F9;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 5rem;
                    height: 5rem;
                }
            }
            h4 {
                font-weight: 600;
                font-size: 1.5rem;
                color: #333;
            }
            p {
                font-weight: 500;
                font-size: 1rem;
                color: #4F4F4F;
            }
            a {
                margin: 0 auto;
            }
        }   
    }    
}

@media screen and (max-width: 768px) {
    .three-columns {
        .row {
            flex-direction: column;
            align-items: center;
            .col {
                text-align: center;
                margin-bottom: 1rem;
                .img-wrapper {
                    margin: 0 auto;
                }
            }
        }
    }
}