.thank {
    min-height: 585px;
    display: flex;
    align-items: center;
    background-size: cover;
    .title {
        color: #fff;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: unset;
    }
}