.right-image {
    .row {
        align-items: center;
        img {
            width: 100%;
        }
        .subtitle {
            text-transform: uppercase;
            font-family: 'IBM Plex Serif', serif;
        }
        .subtitle-little {
            font-size: 1.1rem;
            color: #007DEF;
            margin-bottom: 2rem;
        }
        .text {
            font-size: 1.25rem;
            margin-bottom: 2.5rem;
        }
    }    
}

@media screen and (max-width: 768px) {
    .right-image {
        .row {
            .subtitle-little {
                font-size: .9rem;
                margin-bottom: 1rem;
            }
            .text {
                font-size: 1rem;
                margin-bottom: 2rem;
            }
        }    
    }
}