.slider-wrapper {
    position: relative;
    .slide-content {
        height: 35rem;
        .row {
            align-items: center;
            height: 100%;
            .slide-title {
                font-weight: 600;
                font-size: 2.8rem;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
            .slide-text {
                font-weight: 500;
                font-size: 1.12rem;
                line-height: 2rem;
                margin-bottom: 2rem;
            }
        }
        }
        .slide-item {
            min-height: 35rem;
            background-size: cover;
            display: flex;
            align-items: center;
            color: #fff;
        }
        .slick-arrow-wrapper {
            position: absolute;
            bottom: 0;
            padding-left: 10%;
            height: 4.5rem;
            background: rgba(47, 54, 61, 0.5);
            backdrop-filter: blur(5px);
            .slick-arrow {
                height: 100%;
                width: 4.5rem;
                background: #262C32;
                color: #fff;
                border-radius: 0;
                &:hover {
                background: #fff;
                color: #262C32;
                }
                &:focus {
                outline: none;
                box-shadow: none;
                }
            }
    }
}

@media screen and (max-width: 1100px) {
    .slider-wrapper {
        .slide-content {
            .row {
                .slide-title {
                    font-size: 1.5rem;
                }
                .slide-text {
                    font-weight: 500;
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                }
            }
        }
    }
}