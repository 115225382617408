.left-image {
    .row {
        background: #262C32;
        align-items: center;
        .left-col {
            img {
                width: 100%;
            }
        }
        .right-col {
            background: #262C32;
            h3 {
                font-weight: 600;
                font-size: 2rem;
                text-transform: uppercase;
                color: #FFFFFF;
                line-height: 3.5rem;
            }
            h4 {
                font-weight: 500;
                font-size: 1.75rem;
                color: #007DEF;
                line-height: 3rem;
            }
            p {
                font-size: 1.1rem;
                line-height: 2rem;
                color: #F3F5F9;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .left-image {
        .row {
            .right-col {
                h3 {
                    font-size: 1.1rem;
                    line-height: 2.3rem;
                }
                h4 {
                    font-size: 1.1rem;
                    line-height: 1.75rem;
                }
                p {
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                }
            }
        }
    }
}