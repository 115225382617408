.team-list {
    .team-image {
        width: 100%;
    }
    .team-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .team-icon {
            height: 46px;
            width: 46px;
        }
        .team-name {
            font-weight: 600;
            font-size: 2rem;
            line-height: 2rem;
        }
        .team-position {
            font-weight: bold;
            font-size: 1.1rem;
            line-height: 2rem;
            color: #007DEF;
        }
    }
}

@media screen and (max-width: 991px) {
    .team-list {
        .team-wrapper {
            .team-name {
                font-size: 1.2rem;
                line-height: 1.2rem;
            }
            .team-position {
                font-size: 1rem;
                line-height: 1rem;
            }
        }
    }
}