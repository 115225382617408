.carousel-block {
    .slick-carousel-wrapper {
        padding-left: 0;
        .slick-carousel {
            &.slick-slider {
                direction: rtl;
                min-height: 450px;
                .slick-slide {
                    margin: 0 10px;
                    &.slick-active {
                        width: 425px;
                        transition: .5s ease-out;
                    }
                    width: 263px;
                    img {
                        width: 100%;
                    }
                }
            }
            .slick-track {
              display: flex;
            }
            .slick-track .slick-slide {
              display: flex;
              height: 450px;
              align-items: flex-end;
              justify-content: flex-end;
            }
        }
    }
    .slick-carousel-slider {
        .carousel-title {
            font-weight: 600;
            font-size: 2rem;
            line-height: 3rem;
            text-transform: capitalize;
            color: #333333;
        }
        .carousel-subtitle {
            font-weight: bold;
            font-size: 1.1rem;
            line-height: 2rem;
            color: #007DEF;
            margin-bottom: 2rem;
        }
        .carousel-text {
            font-size: 0.9rem;
            line-height: 1.5rem;  
        }
    }
    .slick-carousel-arrow-wrapper {
        position: absolute;
        bottom: 0.3rem;
        background: #F3F5F9;
        .slick-arrow {
            border-radius: 0;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:hover {
                background: #262C32;
                color: #F3F5F9;                
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .carousel-block {
        .container-fluid .row {
            align-items: center;
        }
        .slick-carousel-wrapper {
            .slick-carousel {
                &.slick-slider {
                    min-height: unset;
                    .slick-slide {
                        &.slick-active {
                            width: 165px;
                        }
                        width: 165px;
                    }
                }
                .slick-track .slick-slide {
                    align-items: center;
                    height: unset;
                }
            }
        }
        .slick-carousel-slider {
            .carousel-title {
                font-size: 1rem;
                line-height: 1.5rem;
            }
            .carousel-subtitle {
                font-size: 0.9rem;
                line-height: 1rem;
                margin-bottom: 1rem;
            }
            .carousel-text {
                font-size: 0.75rem;
                line-height: 1.1rem;
                height: 5.5rem;
                overflow: scroll;
            }
        }
        .slick-carousel-arrow-wrapper {
            bottom: -1.5rem;
        }
    }
}

