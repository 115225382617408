#logo {
    width: 10rem;
}
.container {
    img {
        width: 20rem;
    }
}
.nav-link { 
    color: #262C32; 
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5rem;
}
.nav-link { 
    margin-right: 6rem !important;
}
.nav-link:hover { 
    color: #17DA93; 
}
.navbar-collapse { 
    justify-content: flex-end;
}
.navbar-toggler {  
    background:#fff !important;
}
.svg-inline--fa.fa-w-14 {
    width: unset;
    height: 100%;
    color: #17DA93;
} 
.navbar-toggler:focus {
    outline: none;
}

@media screen and (max-width: 1300px) {
    .nav-link { 
        margin-right: 5rem !important;
    }
}

@media screen and (max-width: 1100px) {
    .nav-link { 
        margin-right: 3rem !important;
    }
}