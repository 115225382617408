.not-found {
    .col {
        text-align: center;
        img {
            width: 100%;
            max-width: 910px;
            text-align: center;
        }
        a {
            margin: 2rem auto;
        }
    }
}