.hero-shifted {
    min-height: 585px;
    margin-top: 120px;
    background-size: cover;
    .hero-shifted-container {
        transform: translateY(-100px);
        .hero-shifted-row {
            .hero-shifted-col {
                background: #202529;
                .title {
                    color: #fff;
                    margin-bottom: .5rem;
                }
                .text {
                    color: #fff;
                    margin-bottom: 2rem;
                }
            }
        }
    }   
}