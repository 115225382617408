.multi-form {
    .title {
        font-size: 2.88rem;
        text-align: center;
        margin-bottom: 1rem;
    }
    .subtitle {
        font-size: 1.1rem;
        text-align: center;
        color: #4F4F4F;
    }
    #regForm {
        max-width: 650px;
        margin: 0 auto;
    }
    .tab {
        display: none;
        margin-top: 2rem;
        font-size: 1.14rem;
        input, textarea {
            display: block;
            width: 100%;
            padding: 1rem;
            border: none;
            border: 1px solid #F4F4F4;
            background-color: #F4F4F4;
            &.invalid {
                border: 1px solid red;
            }
        }
        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #E0E0E0;
            display: inline-block;
            padding: 1rem 3rem;
            cursor: pointer;
            font-family: 'IBM Plex Sans';
            font-size: 1.38rem;
            color: #17DA93;
            &:hover {
                border: 1px solid #17DA93;
            }
        }
    }
    .btn-wrapper {
        text-align: center;
        .btn-outline {
            display: inline-block;
            margin: 1rem;
        }
    }
    
    .steps {
        text-align: center;
        font-family: 'IBM Plex Sans';
        font-size: 22px;
        color: #17DA93;
        .step {
            display: inline-block;
            border: 1px solid #17DA93;
            border-radius: 50%;
            height: 58px;
            width: 58px;
            padding: .7rem;
            margin-right: 17rem;
            position: relative;
            text-align: center;
            &:before,
            &:after {
                content:'';
                width: 17rem;
                border-bottom: 1px solid #17DA93;
                position: absolute;
                top: 50%;
            }
            &:after {
                left:100%;
            }
            &:before {
                right:100%;
            }
            &:first-of-type:before,
            &:last-of-type:after {
                display:none;
            }
            &:last-of-type {
                margin-right: 0;
            }
              
            &.active {
                background: #17DA93;
                color: #fff;
            }
            &.finish {
                background: #17DA93;
                color: #fff;
            }
        }
    }   
}

@media screen and (max-width: 768px) {
    .multi-form {
        padding-right: 15px;
        .steps {
            .step {
                margin-right: 10rem;
                &:before,
                &:after {
                    width: 10rem;
                }
            }
        }
    }
}