.five-icons {
    .row {
        &.three-col {
            justify-content: space-between;
        }
        &.two-col {
            justify-content: space-evenly;
        }
        .col {
            max-width: 19.3rem;
            .image-wrapper {
                width: 5.75rem;
                height: 5.75rem;
                background: #F3F5F9;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                img {
                    width: 3.35rem;
                    height: 3.35rem;
                }
            }
            p {
                font-weight: bold;
                font-size: 1.1rem;
                line-height: 2rem;
                text-align: center;
                color: #333333;
            }
        }        
    }
}

@media screen and (max-width: 768px) {
    .five-icons {
        .row {
            flex-direction: column;
            align-items: center;
            .col {
                p {
                    font-size: 1rem;
                    line-height: 1.75rem;
                }
            }
        }
    }
}